// Custom Variable

$lightGreen: #8cb560;
$darkGreen:#59882b;
$yellow:#febd00;
$lightGray:#f8fbf5;
$lightBlack:#212529;



// Custom Theme

@import "./Theme.scss";

// Fonts
// @import url('https://fonts.googleapis.com/css2?family=Cookie&family=Josefin+Sans:wght@600;700&family=Poppins:wght@400;500&display=swap');


@import "../../node_modules/bootstrap/scss/bootstrap";


// General Style

body {
    font-display: swap;
    font-family: 'Poppins', sans-serif;
    color: #545454;
    @include font-size(1.125em);

    @include media-breakpoint-down(xxl) {
        font-size: 16px;
    }
}

.bold {
    font-weight: bold;
}

.mb-section {
    margin-bottom: 150px;

    @include media-breakpoint-down(lg) {
        margin-bottom: 85px;
    }
}

.heading,
.sub-heading {
    text-transform: capitalize;
}

.heading {
    @include font-size(2.5rem);
    font-family: 'Josefin Sans', sans-serif;
    font-weight: bold;
    color: $lightBlack;
    margin-bottom: 1.8rem;
    margin-top: 1.5rem;

    @include media-breakpoint-down(xxl) {

        @include font-size(1.5rem);

    }
}

.sub-heading {
    @include font-size(1.75rem);
    font-family: 'Cookie', cursive;
    color: $yellow;

    @include media-breakpoint-down(xxl) {

        @include font-size(1.25rem);

    }
}

.p-desc {
    @include font-size(1.125rem);
    margin-top: 1.5rem;

    @include media-breakpoint-down(xxl) {

        @include font-size(1rem);

    }
}

// Form

.form-floating {
    >label {

        font-size: 1.125em;
        color: #B7B7B7;


    }

    .form-control {
        background-color: #ffffff;
        border: none;
        box-shadow: 0px 5px 40px rgba(0, 0, 0, 0.07);
    }
}